import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate, redirect, Navigate } from "react-router-dom";
import axios from "axios";
import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import Home from "./pages/Home";
//import About from "./pages/About";
import Ulocells from "./pages/ulocells/Ulocells"

import Header from "./components/nav/Header";
import { PageNotFound } from "./pages/auth/PageNotFound";
//import ErrorPage from "./pages/auth/ErrorPage"
import PcrfLogs from "./pages/pcrfLogsHuawei/PcrfLogs";
import PcrfProteiPackages from "./pages/pcrfProtei/PcrfProteiPackages"
import PcrfProteiOrderedPackagesTest from "./pages/pcrfProteiTest/PcrfProteiOrderedPackagesTest"
import PcrfProteiOrderedPackages from "./pages/pcrfProtei/PcrfProteiOrderedPackages"
import PcrfProteiLogs from "./pages/pcrfProteiLogs/PcrfProteiLogs"
import SubData from "./pages/ipLook/SubData";
import CallForward from "./pages/ipLook/CallForward";
import SubsServices from "./pages/ipLook/SubsServices";
import AddKi from "./pages/ipLook/AddKi"
import Staff from "./pages/staff/Staff";
import Alarms from "./pages/alarms/Alarms";
import BtsDown from "./pages/alarms/BtsDown";
import Usrinf from "./pages/usrinf/Usrinf";
import BorderSubs from "./pages/borderSubs/BorderSubs"
import { useAuth } from "./contexts/AuthContext"
import { jwtDecode } from "jwt-decode"


const App = () => {
  const location = useLocation();
  const navigate = useNavigate()

  const { user, setUser, setError } = useAuth()
  const [isLoading, setIsLoading] = useState(true)


  const refreshToken = async () => {
    //console.log("Attempting to refresh token...");
    try {
      const { data } = await axios.get("/api/refresh", { withCredentials: true });
      //console.log("Refresh token response data:", data);

      if (data) {
        setUser(data);
        //console.log("Token refreshed and user data updated:", data);
      } else {
        //console.warn("No user data returned. Redirecting to login.");
        navigate("/login"); // Если data нет, то сразу перенаправляем
      }
    } catch (err) {
      //console.error("Error refreshing token:", err.message || err);
      setUser(null);
      //console.log("Navigating to /login...");
      navigate("/login", { state: { path: location.pathname } });
      //console.log("Should have navigated to /login");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkAccessToken = async () => {
      //console.log("Checking access token...");
 

      if (user) {
        const decodedAT = jwtDecode(user.accessToken);
        if (decodedAT.exp < Date.now() / 1000) {
          //console.log("Access token expired. Refreshing...");
          await refreshToken();
        } else {
          //console.log("Access token is valid. No need to refresh.");
          setIsLoading(false);
        }
      } else {
        //console.log("User is not set. Attempting to refresh token.");
        await refreshToken();
      }
    };


    if (location.pathname !== "/login" && location.pathname !== "/logout") {
      checkAccessToken();
    } else {
      setIsLoading(false);
    }
  }, [location.pathname, user]);


  if (isLoading) return <div>Загрузка...</div>;


  return (
    <>
      {user && <Header />}
      <Routes>

        <Route exact path="/bts_down" element={<BtsDown />}></Route>

        <Route exact path="/" element={user ? <Home /> : <Login />}></Route>

        <Route exact path="/home" element={<Home />}></Route>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/logout" element={<Logout />}></Route>

        <Route exact path="/ulocells" element={<Ulocells />}></Route>

        <Route exact path="/iplook-subdata" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <SubData /> : <Home message="Доступ запрещен" />}></Route>
        <Route exact path="/iplook-cf" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <CallForward /> : <Home message="Доступ запрещен" />}></Route>
        <Route exact path="/iplook-subsservices" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <SubsServices /> : <Home message="Доступ запрещен" />}></Route>
        <Route exact path="/iplook-ki" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <AddKi /> : <Home message="Доступ запрещен" />}></Route>

        <Route exact path="/border-subs" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <BorderSubs /> : <Home message="Доступ запрещен" />}></Route>
        <Route exact path="/pcrf-protei-ordered-packages" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <PcrfProteiOrderedPackages /> : <Home message="Доступ запрещен" />}></Route>
        <Route exact path="/pcrf-protei-ordered-packages-test" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <PcrfProteiOrderedPackagesTest /> : <Home message="Доступ запрещен" />}></Route>

        <Route exact path="/pcrf-protei-packages" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <PcrfProteiPackages /> : <Home message="Доступ запрещен" />}></Route>
        <Route exact path="/pcrf-protei-logs" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <PcrfProteiLogs /> : <Home message="Доступ запрещен" />}></Route>

        <Route exact path="/pcrf-logs" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <PcrfLogs /> : <Home message="Доступ запрещен" />}></Route>

        <Route exact path="/staff" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <Staff /> : <Home message="Доступ запрещен" />} ></Route>
        <Route exact path="/alarms" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <Alarms /> : <Home message="Доступ запрещен" />} ></Route>

        <Route exact path="/usrinf" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <Usrinf /> : <Home message="Доступ запрещен" />} ></Route>

        <Route path="*" element={<PageNotFound />}></Route>
      </Routes >
    </>
  );
};

export default App;